import './App.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LoginApp from './components/LoginApp';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
          <Router>
          <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
            <Switch>
              <Route exact path='/' component={LoginApp} />
            </Switch>
          </Router>
    </>
  );
}

export default App;

import React, { useEffect } from 'react'
import './loginapp.scss'
import { API_URL } from '../ApiURL';
import axios from 'axios';

const LoginApp = () => {

    const callApi = () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let id = params.get('token');
        setTimeout(() => {
            axios.get(`${API_URL}/api1/user/verify/link?token=${id}`)
                .then((res) => {
                    console.log("res", res)
                }).catch((err) => {
                    return err;
                })

        }, Math.round(Math.random() * (5000 - 2500) + 2500));
    }


    useEffect(() => {
        callApi();
    }, []);
    return (
        <>
            <header id="header" class="fixed-top ">
                <div class="container d-flex align-items-center">

                    <h1><img src="/logo11.svg" style={{ height: 60, width: '100%', display: 'block', margin: 'auto' }} alt="" /></h1>
                    <a href="index.html" class="logo mr-auto"  rel="noopener noreferrer" ><img src="assets/img/logo.png" alt="" class="img-fluid" /></a>

                    <nav class="nav-menu d-none d-lg-block">
                    </nav>
                </div>
            </header>
            <section id="hero" class="d-flex align-items-center">

                <div class="container">
                    <div class="row align-items-center ">
                        <div className='col-lg-6'>
                            <div class="d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <p className='text-white text_congrats'>Congratulations!</p>
                                <h1>Your Account Has Been Verified</h1>
                                {/* <div class="d-lg-flex">
                            <a href="#" class="btn-get-started scrollto">Get Started With the App</a>
                        </div> */}
                            </div>
                            <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src="https://emptrepreneur.s3-ap-southeast-1.amazonaws.com/hero-img.png" class="img-fluid animated" alt="" />
                            </div>
                        </div>
                        {/* <div className="row"> */}
                        <div className='col-lg-6'>
                            <div className="col-sm-12 text-center">
                            <p className='text-white'>Please Login to the Mobile App Now</p><br/>
                                    <p className='text-white text__text_download'>Click the links below to download:</p>
                                <div className="d-flex justify-content-center align-items-center">
                                    <a  href='https://apps.apple.com/gb/app/legion-network/id1602921528' rel="noopener noreferrer"  target='_blank' className="btn shadow-none">
                                        <img src="\appstore-button.png" alt="" className="img-fluid" />
                                    </a>
                                    <a href='https://play.google.com/store/apps/details?id=com.legion.production'  rel="noopener noreferrer"  className="btn shadow-none">
                                        <img src="\googleplay-button.png" alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>

            </section>
        </>
    )
}

export default LoginApp
